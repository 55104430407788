import { createContext, useState } from 'react';
import useLocalStorage from '../hooks/useLocalStorage';

export const AppAuthContext = createContext();

const AppAuthProvider = ({ children }) => {

  const [userAccessToken, setUserAccessToken] = useLocalStorage(`${process.env.REACT_APP_PREFIX}_act`);
  const [userRefreshToken, setUserRefreshToken] = useLocalStorage(`${process.env.REACT_APP_PREFIX}_rft`);

  const [authenticatedUser, setAuthenticatedUser] = useState();
  const [authenticatedUserLoading, setAuthenticatedUserLoading] = useState(true);
  const [authenticatedUserError, setAuthenticatedUserError] = useState();

  const providerValue = {
    userAccessToken,
    setUserAccessToken,
    userRefreshToken,
    setUserRefreshToken,
    
    authenticatedUser,
    setAuthenticatedUser,
    authenticatedUserLoading,
    setAuthenticatedUserLoading,
    authenticatedUserError,
    setAuthenticatedUserError
  };
  

  return (
    <AppAuthContext.Provider value={ providerValue }>
      {children}
    </AppAuthContext.Provider>
  )
};

export default AppAuthProvider;