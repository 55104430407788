import useAppHeader from './logic/useAppHeader';
import Navbar from 'react-bootstrap/Navbar';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Spinner from 'react-bootstrap/Spinner';
import { NavLink } from 'react-router-dom';

import logo from '../../assets/img/ai-logo.svg';
import './AppHeader.css';

const AppHeader = () => {

  const { userAccessToken, authenticatedUser, authenticatedUserLoading } = useAppHeader();

  if(!userAccessToken) return null;

  const UserLabel = () => {
    if(authenticatedUserLoading) return <Spinner size='sm' />;
    return authenticatedUser?.name || '⚠️'; 
  };

  return <Navbar className="appHeader"  variant="dark" expand="md" sticky="top">
    <Container>
      <Navbar.Brand href="./">
        <img alt="Avaxia AI BOT" src={logo} /> <span>Ivexia Agent</span>
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="responsive-avx-nav" />
      <Navbar.Collapse id="responsive-avx-nav" className="justify-content-end">
        <Nav>
          <NavLink className="nav-link" to="/">Home</NavLink>
          <Nav.Link as="span">
            | <label className="userLabel"><UserLabel /></label> |
          </Nav.Link>
          <NavLink className="nav-link" to="/logout">Sign out</NavLink>
        </Nav>
      </Navbar.Collapse>
    </Container>
  </Navbar>
};
export default AppHeader;