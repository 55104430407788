import AppLayout from '../../components/AppLayout/AppLayout';
import { Link } from 'react-router-dom';

import './Page403.css';

const Page403 = () => {
  return <AppLayout pageName="Unauthorized" pageClass="page403" >
    <p>You're not authorized to access this page!</p>
    <Link to="/">Go home</Link>
  </AppLayout>
};

export default Page403;