import bcrypt from 'bcryptjs-react';

const checkCrypt = async (plain, stored) => await bcrypt.compare(plain, stored);

const resCode = {
  'success'   : { data : { accessToken: 'ax'+Math.random(), refreshToken: 'rx'+Math.random() } },
  'denied'    : { response: { data: { code: 6010, message: 'Unauthorized' } } },
  '404'       : { response: { data: { code: 6004, message: 'Unauthorized' } } },
  'suspended' : { response: { data: { code: 6003, message: 'Unauthorized' } } }
};

const signIn = async (signInObject) => {
  try{
    await new Promise((resolve, reject) => setTimeout(resolve, 1500));
    const {username, password} = signInObject;
    
    const userCheck = await checkCrypt(username, process.env.REACT_APP_LOGIN);
    if(!userCheck) throw (resCode['404']);

    const passCheck = await checkCrypt(password, process.env.REACT_APP_PPSWD);
    if(!passCheck) throw (resCode['denied']);

    return resCode['success'];
  }catch(e){
    throw e;
  }

};

const loginService = {
  signIn
};

export default loginService;
