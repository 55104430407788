import './AppFooter.css';

const AppFooter = () => {
  return (
    <footer className="appFooter">
      <p>Copyright &copy; {new Date().getFullYear()} Avaxia. All Rights Reserved.</p>
      <p>Powered by <a href="https://www.avaxiagroup.com" target="_blank" rel="noopener noreferrer">Avaxia</a></p>
    </footer>
  )
};
export default AppFooter;