import Spinner from 'react-bootstrap/Spinner';

import './AppLoader.css';

const AppLoader = () => {
  return <div className="pageLoaderContainer">
    <Spinner variant="dark" role="status" />
  </div>
};

export default AppLoader;