import Container from 'react-bootstrap/Container';
import Alert from 'react-bootstrap/Alert';
import './AppLayout.css';

const AppLayout = ({pageName, pageClass, children}) => {
  if(!pageName || !pageClass){
    console.error('Missing Props : pageName or pageClass');
    return <Alert variant="danger" className="missingPropsAlert">Missing Props : pageName or pageClass</Alert>
  }

  return <div className={"page "+pageClass}>
    <section className="mainContent">
      <Container>
        {children}
      </Container>
    </section>
  </div>
};

export default AppLayout;