import AppLayout from '../../components/AppLayout/AppLayout';
import { Link } from 'react-router-dom';

import './Page404.css';

const Page404 = () => {
  return <AppLayout pageName="Page not found" pageClass="page404" >
    <p>It looks like you're lost!</p>
    <Link to="/">Go home</Link>
  </AppLayout>
};

export default Page404;