import React, { useEffect, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import reportWebVitals from './core/reportWebVitals';

import AppAuthProvider from './core/contexts/AppAuthProvider';
import AppHeader from './components/AppHeader/AppHeader';
import AppFooter from './components/AppFooter/AppFooter';
import AppLoader from './components/AppLoader/AppLoader';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';

import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/mainStylesheet.css';

import Page404 from './pages/404/Page404';
import Page403 from './pages/403/Page403';
import LoginPage from './pages/LoginPage/LoginPage';
import UseLogout from './core/hooks/UseLogout';

const Home = React.lazy( () => import('./pages/Home/Home') );
const Civilis = React.lazy( () => import('./pages/Civilis/Civilis') );
const Arcanus = React.lazy( () => import('./pages/Arcanus/Arcanus') );


const MainApp = () => {

  //hide init loader when html document is fully loaded
  useEffect(() => {
    const hideAppLoader = () => document.getElementById('avxInitLoader').classList.add('hiddenLoader');
    if(document.readyState==='complete') hideAppLoader();
    else{
      window.addEventListener('load', hideAppLoader);
      return () => window.removeEventListener('load', hideAppLoader);
    }
  }, []);

  return (
    <>
      <AppHeader />
      
      <Routes>
        {/* Private Routes -> Require Auth */}
        <Route path="/" element={ <ProtectedRoute /> } >
          <Route index element={ <Suspense fallback={<AppLoader />}><Home /></Suspense> } />
          <Route path="civilis" element={ <Suspense fallback={<AppLoader />}><Civilis /></Suspense> } />
          
          {/* Private Routes With Restricted Access -> Require Permission */}
          <Route path="arcanus" element={ <ProtectedRoute permission="access-arcanus" /> } >
            <Route index element={ <Suspense fallback={<AppLoader />}><Arcanus /></Suspense> } />
          </Route>
        </Route>

        {/* Public Routes -> No Auth Required */}
        <Route path='/login' element={ <LoginPage /> } />
        <Route path='/logout' element={ <UseLogout /> } />
        <Route path='/403' element={ <Page403 /> } />
        <Route path='*' element={ <Page404 /> } />
      </Routes>

      <AppFooter />
    </>
  );
};

const root = ReactDOM.createRoot(document.getElementById('mainApp'));
root.render(
  <React.StrictMode>
    <BrowserRouter basename="/">
      <AppAuthProvider>
        <MainApp />
      </AppAuthProvider>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();