import useLogin from './logic/useLogin';
import { Navigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Alert from 'react-bootstrap/Alert';


import './LoginPage.css';

const LoginPage = () => {

  const {
    loginLoading, loginError,
    userAccessToken, submitLogin,
    register, handleSubmit, isSubmitted, errors
  } = useLogin();

  if(userAccessToken) return <Navigate to="/" replace />

  const buttonLabel = loginLoading ? <Spinner as="span" size="sm" role="status" aria-hidden /> : <span>Sign in..</span>;

  return <div className="page loginPage">
    <div className="loginFormContainer">
      {loginError && <Alert variant="danger">{loginError}</Alert>}
      
      <Form className="loginForm" onSubmit={handleSubmit(submitLogin)}>
        <Form.Group className="mb-3" controlId="username">
          <Form.Label>Username</Form.Label>
          <Form.Control type="text" placeholder="Enter username"  {...register('username')} isInvalid={isSubmitted && errors.username} />
        </Form.Group>
        <Form.Group className="mb-3" controlId="password">
          <Form.Label>Password</Form.Label>
          <Form.Control type="password" placeholder="Password" {...register('password')} isInvalid={isSubmitted && errors.password} autoComplete="off" />
        </Form.Group>
        <div className="d-grid">
          <Button disabled={loginLoading} variant="dark" type="submit">{buttonLabel}</Button>
        </div>
      </Form>
    </div>
  </div>
};

export default LoginPage;