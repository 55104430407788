import { useState } from 'react';
import useAuth from '../../../core/hooks/useAuth';
import loginService from './loginService';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import loginSchema from './loginValidationSchema';

const useLogin = () => {

  const { userAccessToken, setUserAccessToken, setUserRefreshToken } = useAuth();

  const { register, handleSubmit, formState: { isSubmitted, errors } } = useForm({ resolver: yupResolver(loginSchema) });

  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState('');

  const signIn = async (data) => {
    setLoginError('');
    setLoginLoading(true);

    try{
      const response = await loginService.signIn(data);
      //console.log("API LOGIN RESPONSE", response)
      if(response.data){
        const { accessToken, refreshToken } = response.data;
        setUserAccessToken( accessToken );
        setUserRefreshToken( refreshToken );
        //return <Navigate to="/" replace />
      }
    }catch(error){
      if(error.response?.data){
        console.error('API LOGIN ERROR :', error.response.data);
        const errorCode = error.response.data.code;
        switch (errorCode) {
          case 6009:
          case 6010: setLoginError('Access Denied !'); break;
          case 6004: setLoginError('Wrong Username / Password !'); break;
          case 6003: setLoginError('Suspended Account !'); break;
          default: setLoginError('An error has occurred !');
        }
      }else{
        setLoginError('An error has occurred !');
      }
    }finally{
      setLoginLoading(false);
    }
  }

  const submitLogin = (loginData) => signIn(loginData);

  return {
    loginLoading,
    loginError,
    userAccessToken,

    submitLogin,
    register,
    handleSubmit,
    isSubmitted,
    errors
  };
};

export default useLogin;