import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "./useAuth";

const UseLogout = () => {
  const navigate = useNavigate();
  const { setUserAccessToken, setUserRefreshToken, setAuthenticatedUser } = useAuth();

  useEffect(() => {
    setUserAccessToken();
    setUserRefreshToken();
    setAuthenticatedUser();

    navigate('/login', {replace: true});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return null;
};

export default UseLogout;