import { useEffect, useCallback } from 'react';
import useAuth from '../../../core/hooks/useAuth';
import authService from '../../../core/services/authService';

const useAppHeader = () => {

  const {
    userAccessToken,
    authenticatedUser, setAuthenticatedUser,
    authenticatedUserLoading, setAuthenticatedUserLoading
  } = useAuth();

  const getAuthenticatedUserData = useCallback( async () => {
    setAuthenticatedUserLoading(true);
    try{
      const response = await authService.getAuthenticatedUserData();
      if(response.data){
        const userData = response.data;
        setAuthenticatedUser(userData);
      }
    }catch(error){
      console.error('AUTHENTICATED USER API ERROR :', error);
    }finally{
      setAuthenticatedUserLoading(false);
    }
  }, [setAuthenticatedUser, setAuthenticatedUserLoading] );

  useEffect(() => {
    userAccessToken && getAuthenticatedUserData();
  }, [userAccessToken, getAuthenticatedUserData]);

  return {
    userAccessToken,
    authenticatedUser,
    authenticatedUserLoading
  }
};

export default useAppHeader;