import { useState } from 'react';

const useLocalStorage = (key, defaultValue = null) => {
  const [storedValue, setStoredValue] = useState( () => {
    try{
      const value = window.localStorage.getItem(key);
      return value || defaultValue;
    }catch(err){
      return defaultValue;
    }
  });

  const setValue = (newValue) => {
    try{
      if(newValue) window.localStorage.setItem(key, newValue);
      else window.localStorage.removeItem(key);
    }catch(err){}
    setStoredValue(newValue);
  };

  return [storedValue, setValue];
};

export default useLocalStorage;