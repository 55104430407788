const getAuthenticatedUserData = async () => {
  try{
    const userObject = {
      "id" : "MDKHLCHRN",
      "name" : "Flène Ben Foulen",
      "permissions" : ["access-chatbot"]
    };
    //const userError = { response: { data: { code: 6003, message: 'Unauthorized' } } };
    await new Promise((resolve, reject) => setTimeout(resolve, 500));

    //throw userError;
    return { data: userObject };
  }catch(e){
    throw e;
  }
};

const authService = {
  getAuthenticatedUserData
};

export default authService;